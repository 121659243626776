import React from 'react';
import { Modal, Typography } from 'antd';

const NewsDetailsModal = ({ isVisible, onClose, newsItem }) => {
    if (!newsItem) return null;

    return (
        <Modal
            visible={isVisible}
            title={newsItem.brief_description}
            onCancel={onClose}
            footer={null}
        >
            <img
                src={`${process.env.REACT_APP_URL}${newsItem.cover}`}
                alt={newsItem.brief_description}
                className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <Typography.Paragraph>{newsItem.description}</Typography.Paragraph>
        </Modal>
    );
};

export default NewsDetailsModal;
