import React, { useState, useEffect } from 'react';
import { FaTimes, FaFlag, FaBell, FaSignOutAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { message } from "antd";
import { updateUserProfile } from "../../../../api/userProfile";

const SettingsModal = ({ isOpen, onClose }) => {
    const [language, setLanguage] = useState('en');
    const [notification, setNotification] = useState(true);
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        const savedLang = localStorage.getItem('lang');
        const savedNotification = localStorage.getItem('notification') === 'true';
        const savedAddress = localStorage.getItem('address') || '';
        const savedPhoneNumber = localStorage.getItem('phoneNumber') || '';
        const savedFullName = localStorage.getItem('fullName') || '';

        if (savedLang) setLanguage(savedLang);
        setNotification(savedNotification);
        setAddress(savedAddress);
        setPhoneNumber(savedPhoneNumber);
        setFullName(savedFullName);
    }, []);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    const handleNotificationChange = () => {
        setNotification(!notification);
    };

    const handleSave = async () => {
        try {
            const data = {
                fullName,
                phoneNumber,
                address,
                language,
                notification
            };
            const response = await updateUserProfile(data);
            localStorage.setItem('lang', language);
            localStorage.setItem('notification', notification);
            localStorage.setItem('address', address);
            localStorage.setItem('phoneNumber', phoneNumber);
            localStorage.setItem('fullName', fullName);
            setShowConfirmation(true);
            setTimeout(() => setShowConfirmation(false), 2000);
            message.success('Профиль успешно обновлен');
        } catch (error) {
            message.error('Ошибка при обновлении профиля');
        }
    };

    const handleSignOut = () => {
        localStorage.removeItem('token');
        localStorage.setItem('login', false);
        localStorage.setItem('greetings', false);
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('password');
        localStorage.removeItem('token');
        onClose();
        window.location.reload();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3"
            >
                <div className="flex justify-between items-center p-4 border-b">
                    <h3 className="text-xl font-semibold">Настройки</h3>
                    <button onClick={onClose} className="text-gray-700 hover:text-red-500">
                        <FaTimes className="text-xl" />
                    </button>
                </div>
                <div className="p-4">
                    {/* Языковые настройки */}
                    <div className="mb-4">
                        <h4 className="font-semibold mb-2">Выбор языка</h4>
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="lang-uz"
                                name="language"
                                value="uz"
                                checked={language === 'uz'}
                                onChange={() => handleLanguageChange('uz')}
                            />
                            <label htmlFor="lang-uz" className="ml-2 flex items-center">
                                <FaFlag className="text-green-500 mr-2" />
                                O'zbekcha
                            </label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="lang-ru"
                                name="language"
                                value="ru"
                                checked={language === 'ru'}
                                onChange={() => handleLanguageChange('ru')}
                            />
                            <label htmlFor="lang-ru" className="ml-2 flex items-center">
                                <FaFlag className="text-blue-500 mr-2" />
                                Русский
                            </label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id="lang-en"
                                name="language"
                                value="en"
                                checked={language === 'en'}
                                onChange={() => handleLanguageChange('en')}
                            />
                            <label htmlFor="lang-en" className="ml-2 flex items-center">
                                <FaFlag className="text-red-500 mr-2" />
                                English
                            </label>
                        </div>
                    </div>
                    {/* Настройки профиля */}
                    <div className="mb-4">
                        <h4 className="font-semibold mb-2">Настройки профиля</h4>
                        <div className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                id="notification"
                                checked={notification}
                                onChange={handleNotificationChange}
                            />
                            <label htmlFor="notification" className="ml-2 flex items-center">
                                <FaBell className="mr-2" />
                                Отключить уведомления
                            </label>
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700 mb-1" htmlFor="fullName">
                                Изменить имя
                            </label>
                            <input
                                type="text"
                                id="fullName"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700 mb-1" htmlFor="address">
                                Изменить адрес
                            </label>
                            <input
                                type="text"
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-gray-700 mb-1" htmlFor="phoneNumber">
                                Изменить номер телефона
                            </label>
                            <input
                                type="text"
                                disabled={true}
                                contentEditable={false}
                                id="phoneNumber"
                                value={phoneNumber}
                                onClick={() => {
                                    message.info('обратитесь в службу поддержки')
                                }}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end p-4 border-t">
                        <button onClick={handleSave}
                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 mr-2">
                            Сохранить
                        </button>
                        <button onClick={handleSignOut}
                                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-700 flex items-center">
                            <FaSignOutAlt className="mr-2" />
                            Выйти из профиля
                        </button>
                    </div>
                    {showConfirmation && (
                        <div className="text-center text-green-500 mt-4">
                            Изменения сохранены!
                        </div>
                    )}
                </div>
            </motion.div>
        </div>
    );
};

export default SettingsModal;
