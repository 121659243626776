import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { motion } from 'framer-motion';
import pizza_table from '../../assets/img/pizza_table.png';
import cash from '../../assets/icons/cash_icon.png';
import item_leaft from '../../assets/items/leaft_item.png';
import item_mushroom from '../../assets/items/mushroom_item.png';
import item_bolgar from '../../assets/items/bolgar_item.png';
import item_half_tomato from '../../assets/items/half_tomato.png';
import item_small_leaf from '../../assets/items/small_leafx.png';
import backet from '../../assets/icons/backet.png';
import half_pizza_icon from '../../assets/icons/half_pizza_icon.png';
import { message, Typography } from "antd";
import {toggleDrawer} from "../../store/actions/drawerActions";

export default function Products() {
    const productsData = useSelector(state => state.product.products);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [mycount, setmycount] = useState(0);
    const [selectedSize, setSelectedSize] = useState('Small'); // Устанавливаем 'Small' по умолчанию
    const [selectedTopics, setSelectedTopics] = useState({});
    const [cheeseBoardSelected, setCheeseBoardSelected] = useState(false); // Для отслеживания состояния сырного борта
    const dispatch = useDispatch();
    const apiUrl = process.env.REACT_APP_URL;
    const productID = localStorage.getItem('product_id');
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    useEffect(() => {
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };
        setmycount(getMycount(productID, parsedData));
        console.log(getMycount(productID, parsedData));
    }, [mycount]);

    const getMycount = (productId, data) => {
        // Учитываем количество всех позиций с данным productId
        return data?.products?.filter(product => product?.product_id === productId).reduce((acc, product) => acc + product.count, 0) || 0;
    };

    const updateBasket = (productId, type, size = selectedSize, topics = selectedTopics, cheeseBoard = cheeseBoardSelected) => {
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };

        const productIndex = parsedData.products.findIndex(product =>
            product.product_id === productId && product.size === size
        );

        if (productIndex !== -1) {
            // Если продукт с таким размером уже есть в корзине
            if (type === 'increment') {
                parsedData.products[productIndex].count++;
            } else if (type === 'decrement') {
                if (parsedData.products[productIndex].count > 1) {
                    parsedData.products[productIndex].count--;
                } else {
                    parsedData.products.splice(productIndex, 1);
                }
            } else if (type === 'topicsChange') {
                parsedData.products[productIndex].topics = topics;
            } else if (type === 'cheeseBoardChange') {
                parsedData.products[productIndex].board = cheeseBoard;
            }
        } else if (type === 'increment') {
            // Если продукта с таким размером нет, добавляем его
            parsedData.products.push({ product_id: productId, count: 1, size, topics, board: cheeseBoard });
        }

        localStorage.setItem("basket", JSON.stringify(parsedData));
        setmycount(getMycount(productID, parsedData));
    };

    const handleIncrement = () => {
      if ( currentProduct.isDisabled){
          message.warning('Приносим свои извенения этот продукт пока не доступно!!!')
      }else {
          updateBasket(productID, 'increment');
      }

    };

    const handleDecrement = () => {
        updateBasket(productID, 'decrement');
    };

    const handleSizeChange = (size) => {
        setSelectedSize(size);
        updateBasket(productID, 'sizeChange', size);
    };

    const handleTopicChange = (topicName) => {
        const newTopics = { ...selectedTopics };
        if (newTopics[topicName]) {
            delete newTopics[topicName];
        } else {
            newTopics[topicName] = true;
        }
        setSelectedTopics(newTopics);
        updateBasket(productID, 'topicsChange', selectedSize, newTopics);
    };

    const handleCheeseBoardChange = () => {
        setCheeseBoardSelected(!cheeseBoardSelected);
        updateBasket(productID, 'cheeseBoardChange', selectedSize, selectedTopics, !cheeseBoardSelected);
    };

    // Определение доступных размеров и их цены
    const availableSizes = () => {
        const sizes = [];
        if (currentProduct?.size_small_price != null) {
            let price = parseFloat(currentProduct.size_small_price);
            if (cheeseBoardSelected && currentProduct?.chees_board_size_small_price != null) {
                price += parseFloat(currentProduct.chees_board_size_small_price);
            }
            sizes.push({ size: 'Small', price: price.toFixed(2) });
        }
        if (currentProduct?.size_medium_price != null) {
            let price = parseFloat(currentProduct.size_medium_price);
            if (cheeseBoardSelected && currentProduct?.chees_board_size_medium_price != null) {
                price += parseFloat(currentProduct.chees_board_size_medium_price);
            }
            sizes.push({ size: 'Medium', price: price.toFixed(2) });
        }
        if (currentProduct?.size_large_price != null) {
            let price = parseFloat(currentProduct.size_large_price);
            if (cheeseBoardSelected && currentProduct?.chees_board_size_large_price != null) {
                price += parseFloat(currentProduct.chees_board_size_large_price);
            }
            sizes.push({ size: 'Large', price: price.toFixed(2) });
        }
        return sizes;
    };


    useEffect(() => {
        const parsedProductID = parseInt(productID, 10);

        if (isNaN(parsedProductID)) {
            console.error('Invalid product_id in localStorage');
            return;
        }

        if (productsData && Array.isArray(productsData.products) && productsData.products.length > 0) {
            const selectedIndex = productsData.products.findIndex(product => product.id === parsedProductID);

            if (selectedIndex !== -1) {
                setCurrentIndex(selectedIndex);
                setCurrentProduct(productsData.products[selectedIndex]);
                setSelectedSize('Small'); // Сбрасываем на Small при выборе нового продукта
                setSelectedTopics({});
                setCheeseBoardSelected(false); // Сбрасываем состояние сырного борта
            } else {
                console.warn(`Product with ID ${parsedProductID} not found`);
                setCurrentIndex(-1);
                setCurrentProduct(null);
            }
        }
    }, [productID, productsData]);

    useEffect(() => {
        // Обновляем mycount при изменении текущего продукта
        if (currentProduct) {
            const storedData = localStorage.getItem("basket");
            const parsedData = storedData ? JSON.parse(storedData) : { products: [] };
            const myCount = getMycount(currentProduct.id, parsedData);
            setmycount(myCount);
        }
    }, [currentProduct]);

    const handleSwipe = () => {
        if (touchStartX.current - touchEndX.current > 50) {
            const newIndex = (currentIndex + 1) % productsData.products.length;
            setCurrentIndex(newIndex);
            setCurrentProduct(productsData.products[newIndex]);
            localStorage.setItem('product_id', productsData.products[newIndex].id.toString());
        }

        if (touchStartX.current - touchEndX.current < -50) {
            const newIndex = (currentIndex - 1 + productsData.products.length) % productsData.products.length;
            setCurrentIndex(newIndex);
            setCurrentProduct(productsData.products[newIndex]);
            localStorage.setItem('product_id', productsData.products[newIndex].id.toString());
        }
    };

    const onTouchStart = (e) => {
        touchStartX.current = e.targetTouches[0].clientX;
    };

    const onTouchEnd = (e) => {
        touchEndX.current = e.changedTouches[0].clientX;
        handleSwipe();
    };

    const previousIndex = (currentIndex - 1 + productsData.products.length) % productsData.products.length;
    const nextIndex = (currentIndex + 1) % productsData.products.length;

    const getSizeModifiers = (size) => {
        switch (size) {
            case 'Small':
                return { scale: 0.9, offset: -10 };
            case 'Large':
                return { scale: 1.1, offset: 10 };
            default:
                return { scale: 1, offset: 0 };
        }
    };

    const { scale, offset } = getSizeModifiers(selectedSize);

    // Добавляем массив декоративных элементов
    const decorativeItems = [
        item_leaft,
        item_mushroom,
        item_bolgar,
        item_half_tomato,
        item_small_leaf
    ];

    const generateDecorativeItems = () => {
        const radius = 100;
        const itemCount = Math.min(decorativeItems.length, 10);

        return Array.from({ length: itemCount }, (_, i) => {
            const angle = (i / itemCount) * 2 * Math.PI;
            const x = radius * Math.cos(angle);
            const y = radius * Math.sin(angle);

            return (
                <motion.img
                    key={i}
                    src={decorativeItems[i]}
                    alt={`decorative-item-${i}`}
                    className="absolute"
                    style={{
                        width: '36px',
                        height: '36px',
                        left: `calc(45% + ${x}px)`,
                        top: `calc(75% + ${y}px)`,
                        zIndex: 30,

                    }}
                    animate={{ rotate: currentIndex % 2 === 0 ? 15 : -15 }}
                    transition={{ duration: 0.5, repeat: 'loop' }}
                />
            );
        });
    };

    // Функция для получения текущей цены на основе выбранного размера
    const getCurrentPrice = () => {
        const size = availableSizes().find(s => s.size === selectedSize);
        return size ? size.price : currentProduct?.price;
    };

    return (
        <div className="flex flex-col items-center justify-center relative">
            <motion.h2
                key={currentProduct?.id}
                className="ml-6"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.5 }}
            >
                {currentProduct?.name}
            </motion.h2>
            <div className="relative mt-4 flex items-center justify-center w-full" style={{ height: '300px' }}>
                {currentProduct && (
                    <img
                        src={pizza_table}
                        alt="Pizza Table"
                        className="absolute"
                        style={{
                            width: `${200 + offset}px`,
                            height: `${200 + offset}px`,
                            zIndex: 0,
                            transform: 'translate(0%, 89px)',
                        }}
                    />
                )}
                {productsData.products && (
                    <>
                        {/* Предыдущий продукт */}
                        {currentIndex !== -1 && (
                            <motion.div
                                key={productsData.products[previousIndex].id}
                                className="absolute"
                                style={{ top: '20%', left: '-5%', zIndex: 1 }}
                                initial={{ scale: 0.8, opacity: 0.5 }}
                                animate={{ scale: 0.8, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0.5 }}
                                transition={{ duration: 0.5 }}
                            >
                                <img
                                    src={`${apiUrl}${productsData.products[previousIndex].cover[0]}`}
                                    alt={productsData.products[previousIndex].name}
                                    className="object-cover rounded-full"
                                    style={{ width: '100px', height: '100px' }}
                                />
                            </motion.div>
                        )}
                        {/* Текущий продукт */}
                        {currentProduct && (
                            <motion.div
                                key={currentProduct.id}
                                className="relative z-20"
                                style={{ top: '30%', transform: `translateY(100px) translateX(-50%) scale(${scale})` }}
                                onTouchStart={onTouchStart}
                                onTouchEnd={onTouchEnd}
                                initial={{ scale: 1, opacity: 1 }}
                                animate={{ scale: 1.2, opacity: 1 }}
                                exit={{ scale: 1, opacity: 0.5 }}
                                transition={{ duration: 0.5 }}
                            >
                                <img
                                    src={`${apiUrl}${currentProduct.cover[0]}`}
                                    alt={currentProduct.name}
                                    className="object-cover rounded-full bg-transparent mix-blend-multiply"
                                    style={{ width: `${150 + offset}px`, height: `${150 + offset}px` }}
                                />
                            </motion.div>
                        )}
                        {/* Следующий продукт */}
                        {currentIndex !== -1 && (
                            <motion.div
                                key={productsData.products[nextIndex].id}
                                className="absolute"
                                style={{ top: '20%', right: '-5%', zIndex: 1 }}
                                initial={{ scale: 0.8, opacity: 0.5 }}
                                animate={{ scale: 0.8, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0.5 }}
                                transition={{ duration: 0.5 }}
                            >
                                <img
                                    src={`${apiUrl}${productsData.products[nextIndex].cover[0]}`}
                                    alt={productsData.products[nextIndex].name}
                                    className="object-cover rounded-full mix-blend-multiply"
                                    style={{ width: '100px', height: '100px' }}
                                />
                            </motion.div>
                        )}
                    </>
                )}

                {/* Кнопки + и - */}
                <motion.button
                    className="absolute bottom-5 right-10 p-2 bg-green-500 text-white rounded-full"
                    whileTap={{ scale: 0.9 }}
                    onClick={handleIncrement}
                >
                    +
                </motion.button>
                <motion.button
                    className="absolute bottom-5 left-10 p-2 bg-red-500 text-white rounded-full"
                    whileTap={{ scale: 0.9 }}
                    onClick={handleDecrement}
                >
                    -
                </motion.button>

                {generateDecorativeItems()}
            </div>

            {/* Выбор размера */}
            <div className={'w-full h-fit rounded-xl bg-gray-100/80 flex flex-col '}>
                {/* Анимация количества продуктов в корзине */}
                <motion.div

            onClick={()=>{
                dispatch(toggleDrawer('basket', true,'fully-open'));
            }}


                    key={mycount}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.5 }}
                    className={'text-gray-800 font-bold text-2xl self-center mt-12 flex flex-row items-center justify-center'}
                >
                    <img src={backet} className={'w-[25px] h-[25px] mr-4'} />
                    {mycount === 0 ? 'У вас нет продуктов' : `В корзине ${mycount} шт.`}
                </motion.div>
                <motion.div
                    key={getCurrentPrice()} // Используем цену для выбранного размера
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.5 }}
                >
                    <Typography level={4} className={'text-gray-600 font-semi text-xl font-bold m-auto self-center mt-6'}>
                        {getCurrentPrice()} so'm {/* Отображаем цену на основе выбранного размера */}
                    </Typography>
                    <div className={'flex flex-row w-full items-center justify-center mt-2'}>
                        <img src={cash} className={'w-[25px] h-[25px]'} />
                        <Typography level={2} className={'text-green-600 font-semi text-xl'}>
                            {getCurrentPrice()} so'm
                        </Typography>
                    </div>
                </motion.div>
                <div className=" w-full h-[45px] overflow-scroll p-4  rounded-xl flex flex-row items-center justify-center space-x-4">
                    {availableSizes().map(({ size }) => (
                        <motion.div
                            key={size}
                            className={`flex items-center justify-center w-[60px] h-[30px] rounded-md cursor-pointer transition duration-300 ease-in-out ${
                                selectedSize === size ? 'bg-gray-700 text-white ' : 'bg-white text-gray-700'
                            }`}
                            whileHover={{ scale: 1.1 }}
                            onClick={() => handleSizeChange(size)}
                        >
                            {size}
                        </motion.div>
                    ))}
                    {/* Сырный борт */}
                    {(currentProduct?.chees_board_size_large_price > 0 || currentProduct?.chees_board_size_medium_price > 0 || currentProduct?.chees_board_size_small_price > 0) && (
                        <motion.div
                            className={`p-2 rounded-md cursor-pointer transition duration-300 ease-in-out ${
                                cheeseBoardSelected ? 'bg-green-500 text-white' : 'bg-white text-gray-700'
                            }`}
                            whileHover={{ scale: 1.1 }}
                            onClick={handleCheeseBoardChange}
                        >
                            Сырный борт
                        </motion.div>
                    )}
                </div>

                {/* Контент ниже карусели */}
                <div className="mt-6 w-full h-[200px] overflow-scroll p-4 rounded-lg shadow-lg">
                    <div className="flex flex-row overflow-scroll space-x-4">
                        {currentProduct?.topicons?.map((value, index) => (
                            <motion.div
                                key={index}
                                className={`flex flex-col items-center justify-center w-fit h-[60px] bg-white shadow-md rounded-md p-2 hover:bg-gray-200 transition duration-300 ease-in-out flex-shrink-0 ${
                                    selectedTopics[value.name] ? 'bg-gray-300' : ''
                                }`}
                                whileHover={{ scale: 1.1 }}
                                onClick={() => handleTopicChange(value.name)} // Тогл топик
                            >
                                <Typography level={4} className="text-sm font-bold">
                                    {value.name}
                                </Typography>
                                <Typography level={4} className="text-xs">
                                    {value.price} so'm
                                </Typography>
                            </motion.div>
                        ))}
                    </div>
                    {/* Анимация описания */}
                    <motion.div
                        key={currentProduct?.description}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h3 className="text-2xl font-bold mb-4 ">{currentProduct?.name}</h3>
                        <p>{currentProduct?.description}</p>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
