import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Skeleton } from 'antd';
import { FixedSizeList as List } from 'react-window';
import { getCategoryName } from '../../utils';
import SubCategoryCard from './items/SubCategoryCard';
import { setProductData } from "../../store/actions/productActions";
import { motion } from 'framer-motion';

const SubCategoryCardMemo = memo(SubCategoryCard);

const Row = memo(({ index, style, data }) => {
    const product = data[index];
    if (!product) {
        return <div style={{ ...style, padding: '10px 0', boxSizing: 'border-box' }}><Skeleton active /></div>;
    }
    return (
        <div style={{ ...style, padding: '10px 0', boxSizing: 'border-box' }}>
            <SubCategoryCardMemo key={product.id} product={product} />
        </div>
    );
});

export default function SubCategories() {
    const categories = useSelector(state => state.category.categories);
    const drawerIsOpen = useSelector(state => state.drawer.isOpen);
    const [category, setCategory] = useState();
    const [visibleProductsCount, setVisibleProductsCount] = useState(6);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_URL;
    const dispatch = useDispatch();

    useEffect(() => {
        const selectedCategory = getCategoryName(categories);
        setCategory(selectedCategory);
        setVisibleProductsCount(6); // Сброс видимых продуктов при смене категории
        setLoading(false);
        console.log(selectedCategory);
    }, [drawerIsOpen, categories]);

    useEffect(() => {
        console.log(category?.products);
    }, [category]);

    const handleCategoryClick = (id) => {
        setLoading(true);
        localStorage.setItem('category', id); // Сохранить ID выбранной категории в localStorage

        const selectedCategory = getCategoryName(categories); // Получить обновленную категорию из массива
        setCategory(selectedCategory); // Обновить состояние
        setVisibleProductsCount(6); // Сброс видимых продуктов при смене категории
        setLoading(false);
    };

    const loadMoreProducts = () => {
        setVisibleProductsCount(prevCount => prevCount + 6);
    };

    // Получить текущий выбранный ID из localStorage
    const currentCategoryId = parseInt(localStorage.getItem('category'), 10);

    // Вычислить количество оставшихся продуктов
    const remainingProductsCount = category?.products?.length - visibleProductsCount;

    return (
        <div className='flex flex-col w-full h-full'>
            <Typography className='font-semi'>Категории</Typography>
            <div className='flex flex-row w-full h-fit p-2 rounded-lg mt-4 overflow-scroll '>
                {categories[0]?.map((value) => (
                    <motion.div
                        key={value.id}
                        onClick={() => handleCategoryClick(value.id)}
                        className={`min-w-[100px] h-[40px] flex flex-row ml-2 rounded items-center justify-center p-0 cursor-pointer ${
                            currentCategoryId === value.id ? 'bg-blue-200 border border-blue-500' : 'bg-gray-600/20'
                        }`}
                        whileTap={{ scale: 0.95 }}
                    >
                        <img src={`${apiUrl}${value?.icon?.icon}`} className='w-[25px] h-[25px]' alt={value?.name} />
                        <Typography className='font-thin ml-2'>{value?.name}</Typography>
                    </motion.div>
                ))}
            </div>

            <div className='w-full flex-1 overflow-auto mt-4'>
                {loading ? (
                    Array.from({ length: visibleProductsCount }).map((_, index) => (
                        <Skeleton key={index} active />
                    ))
                ) : (
                    <List
                        height={600}
                        itemCount={category?.products?.slice(0, visibleProductsCount).length || 0}
                        itemSize={220} // Увеличиваем размер элемента для включения отступов
                        width={'100%'}
                        itemData={category?.products?.slice(0, visibleProductsCount) || []}
                    >
                        {Row}
                    </List>
                )}
            </div>

            {remainingProductsCount > 0 && (
                <div className='relative flex justify-center mt-4'>
                    <div className='absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent flex justify-center items-center'>
                        <Button
                            onClick={loadMoreProducts}
                            className="w-full shadow-md bg-blue-500 text-white font-bold py-2 px-4 rounded-lg transition duration-300 hover:bg-blue-600 hover:shadow-lg"
                        >
                            Загрузить еще ({remainingProductsCount})
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
