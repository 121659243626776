import React, { useEffect, useState } from 'react';
import {message, Typography} from 'antd';
import {getCategoryName, truncateString} from '../../../utils';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import cash_icon from '../../../assets/icons/cash_icon.png';
import {toggleDrawer} from "../../../store/actions/drawerActions";
import {useDispatch, useSelector} from "react-redux";
import {setProductData} from "../../../store/actions/productActions";

export default function SubCategoryCard({ product }) {
    const [current, setCurrent] = useState();
    const categories = useSelector(state => state.category.categories);
    const url = process.env.REACT_APP_URL;
    const dispatch = useDispatch();
    // Используем useInView для отслеживания видимости элемента
    const { ref, inView } = useInView({
        triggerOnce: true, // Анимация будет выполнена только один раз при первом появлении
        threshold: 0.1, // 10% элемента должны быть видимы, чтобы сработал триггер
    });

    useEffect(() => {
        setCurrent(product);

    }, [product]);

    useEffect(() => {
        console.log(current);
    }, [current]);

    // Определяем варианты анимации для карточки
    const cardVariants = {
        hidden: { opacity: 0, y: 20, scale: 0.95, rotate: -3 }, // Начальное состояние с легким наклоном и уменьшением
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            rotate: 0,
            transition: {
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99] // Кривая для плавного эффекта
            }
        },
    };

    // Определяем варианты анимации для изображения
    const imageVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { duration: 0.5, delay: 0.3 } },
    };

    return (
        <motion.div
            onClick={()=>{
                if (product.isDisabled){
                    message.warning('Приносим свои извенения этот продукт пока не доступно!!!')
                }else {
                    localStorage.setItem('product_id',current?.id)
                    dispatch(toggleDrawer('product', true,'fully-open'));
                    dispatch(setProductData(getCategoryName(categories)));
                }

            }}
            ref={ref}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={cardVariants}
            className="w-full h-[200px] flex flex-row rounded-lg overflow-hidden shadow-lg bg-gray-100 mt-4 relative"
            whileHover={{ scale: 1.05, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)" }} // Анимация при наведении
        >
            <div className={'flex flex-col w-[50%] justify-between'}>
                <Typography.Title level={4} className={'ml-2 mt-4 '}>{current?.name}</Typography.Title>
                <div className={'w-full bg-white/30 h-fit rounded-md ml-2 mb-2 font-thin p-1'}>
                    {truncateString(current?.description, 40)}
                </div>
                <div className={'flex flex-row w-full h-[25px] p-2 bg-white/80 rounded-md items-center justify-center mb-4 ml-2'}>
                    <img src={cash_icon} className={'w-[20px] h-[20px] mr-2'} alt="Cash Icon" />
                    <Typography level={3} className={'text-gray-800'}>{current?.price}</Typography>
                    <Typography className={'text-green-600 ml-2'} level={3}>so'm</Typography>
                </div>
            </div>
            <motion.img
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={imageVariants}
                className="w-[100px] h-[100px] m-auto self-end"
                src={`${url}${current?.cover[0]}`}
                alt={current?.name}
            />
        </motion.div>
    );
}
