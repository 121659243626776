export const filterProducts = (products, json) => {
    // Проверяем наличие списка продуктов и его тип
    if (!Array.isArray(products)) {
        console.error('Products is not an array');
        return [];
    }

    const productIdsWithCounts = json.products.reduce((acc, item) => {
        acc[item.product_id] = item.count;
        return acc;
    }, {});

    return products
        .filter(product => productIdsWithCounts.hasOwnProperty(product.id) && productIdsWithCounts[product.id] > 0)
        .map(product => ({
            ...product,
            count: productIdsWithCounts[product.id]
        }));
};




export const getCategoryName = (categories) => {
    console.log(categories)
    if (!Array.isArray(categories)) {

        return null;
    }

    const currentID = localStorage.getItem('category');

    if (!currentID) {

        return null;
    }

    // Преобразуем currentID в число, чтобы соответствовать типу id в данных
    const currentIDNumber = parseInt(currentID, 10);

    // Плоский массив всех категорий
    const flattenedCategories = categories.flatMap(subArray => subArray);

    // Ищем категорию с совпадающим type.id
    const category = flattenedCategories.find(cat => cat.id === currentIDNumber);

    if (!category) {

        return null;
    }

    return category;
};

export const truncateString = (text, maxLength) => {
    if (typeof text !== 'string') {

        return '';
    }

    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    } else {
        return text;
    }
};


export const removeBlackBackground = (imgSrc, callback) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imgSrc;

    img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
                data[i + 3] = 0; // Устанавливаем альфа-канал в 0 (прозрачность)
            }
        }

        ctx.putImageData(imageData, 0, 0);
        callback(canvas.toDataURL());
    };

    img.onerror = (error) => {
        console.error("Error loading image:", error);
        callback(null);
    };
};

export function removeProductWithIdZero(data) {
    // Проверяем, существует ли массив products в объекте data
    if (Array.isArray(data.products)) {
        // Фильтруем массив, убирая продукт с product_id равным 0
        data.products = data.products.filter(product => product.product_id !== 0);
    }
    return data;
}



