import React, { useState, useEffect } from 'react';
import { Modal, Button, Radio } from 'antd';
import half_table from '../../../assets/img/half_table.png';
import cover_piknick from '../../../assets/img/cover_piknick.png';

const HalfOrder = ({ left, right, categories }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSide, setSelectedSide] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState({ left: null, right: null });
    const [selectedIds, setSelectedIds] = useState({ pizza_1: null, pizza_2: null });
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [size, setSize] = useState('medium');
    const [board, setBoard] = useState(false);

    useEffect(() => {
        if (categories && categories.categories && categories.categories.length > 0) {
            const products = categories.categories[0].flatMap(category =>
                category.products ? category.products.filter(product => product.isHalf === 1 && product.isDisabled === 0) : []
            );
            setFilteredProducts(products);
        }
    }, [categories]);

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (categories && categories.categories && categories.categories.length > 0) {
            const products = categories.categories[0].flatMap(category =>
                category.products ? category.products.filter(product => product.isHalf === 1 && product.isDisabled === 0) : []
            );
            const filtered = products.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredProducts(filtered);
        }
    };

    const handleClick = (side) => {
        setSelectedSide(side);
        setIsModalVisible(true);
    };

    const handleSelectProduct = (product) => {
        if (selectedSide === 'left') {
            setSelectedProduct(prev => ({ ...prev, left: product.cover[0] }));
            setSelectedIds(prev => ({ ...prev, pizza_1: product.id }));
        } else {
            setSelectedProduct(prev => ({ ...prev, right: product.cover[0] }));
            setSelectedIds(prev => ({ ...prev, pizza_2: product.id }));
        }
        setIsModalVisible(false);
    };

    const handleAddToBasket = () => {
        const basket = JSON.parse(localStorage.getItem('basket')) || { products: [] };
        const newProduct = {
            product_id: null,
            count: 1,
            isHalf: true,
            pizza_1: selectedIds.pizza_1,
            pizza_2: selectedIds.pizza_2,
            size: size,
            board: board
        };
        basket.products.push(newProduct);
        localStorage.setItem('basket', JSON.stringify(basket));
    };

    return (
        <div>
            <div className="relative flex items-center justify-center w-72 h-72" style={{ backgroundImage: `url(${half_table})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="w-32 h-64 overflow-hidden" onClick={() => handleClick('left')}>
                    <div className="w-64 h-64 rounded-full overflow-hidden">
                        <img src={'https://api.italianopizza.uz/' + (selectedProduct.left || left)} alt="Left Half Circle" className="w-64 h-64 object-cover" />
                    </div>
                </div>
                <div className="w-32 h-64 overflow-hidden transform rotate-180" onClick={() => handleClick('right')}>
                    <div className="w-64 h-64 rounded-full overflow-hidden">
                        <img src={'https://api.italianopizza.uz/' + (selectedProduct.right || right)} alt="Right Half Circle" className="w-64 h-64 object-cover" />
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4">
                <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
                    <Radio.Button value="medium">Medium</Radio.Button>
                    <Radio.Button value="large">Large</Radio.Button>
                </Radio.Group>
            </div>
            <div className="flex justify-center mt-4">
                <Radio.Group value={board} onChange={(e) => setBoard(e.target.value)}>
                    <Radio.Button value={true}>Сырный Борт</Radio.Button>
                    <Radio.Button value={false}>Обычный</Radio.Button>
                </Radio.Group>
            </div>
            <div className="flex justify-center mt-4">
                <Button type="primary" onClick={handleAddToBasket}>Добавить в корзину</Button>
            </div>
            <Modal
                title="Выберите продукт"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                bodyStyle={{
                    backgroundImage: `url(${cover_piknick})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    padding: '5px',
                    borderRadius: '10px'
                }}
            >
                <SearchBar onSearch={handleSearch} />
                <div className="flex flex-wrap flex-row justify-center items-center">
                    {filteredProducts.map(product => (
                        <ProductCard key={product.id} product={product} onSelect={handleSelectProduct} />
                    ))}
                </div>
            </Modal>
        </div>
    );
};

const ProductCard = ({ product, onSelect }) => (
    <div className="w-24 h-24 flex mt-8 bg-white rounded-lg flex-col items-center justify-center m-2 cursor-pointer transition-transform transform hover:scale-105" onClick={() => onSelect(product)}>
        <img src={'https://api.italianopizza.uz/' + product.cover[0]} alt={product.name} className="w-20 h-20 object-cover" />
        <div className="text-center">
            <h3 className="text-xs font-bold mt-2">{product.name}</h3>
            <p className="text-xs text-gray-600 bg-white rounded-lg p-[5px] font-bold">{product.price} UZS</p>
        </div>
    </div>
);

const SearchBar = ({ onSearch }) => (
    <input
        type="text"
        placeholder="Поиск продукта"
        onChange={(e) => onSearch(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
    />
);

export default HalfOrder;
