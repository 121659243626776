import React, { useState, useRef, useEffect } from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFilterCircle } from "react-icons/bs";
import logo from '../../../assets/img/logo.png';
import hand_icon from '../../../assets/icons/hand_icon.png';
import cash_icon from '../../../assets/icons/cash_icon.png';
import avatar_icon from '../../../assets/icons/avatar_icon.png';
import chef_icon from '../../../assets/icons/chef_icon.png';
import { Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setProductData } from "../../../store/actions/productActions"; // импортируем action
import {getCategoryName, truncateString} from '../../../utils';
import {toggleDrawer} from "../../../store/actions/drawerActions"; // утилита для получения имени категории

const Header = () => {
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterVisible, setFilterVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('Все');
    const [priceRange, setPriceRange] = useState(100000); // Диапазон цен
    const cashback = localStorage.getItem('cashback');
    const address = localStorage.getItem('address');
    const searchContainerRef = useRef(null);
    const filterContainerRef = useRef(null);
    const categories = useSelector(state => state.category.categories);
    const dispatch = useDispatch();

    // Обработчик кликов вне области поиска и фильтров
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target) &&
                filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
                setSearchFocused(false);
                setFilterVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Функция для сохранения в localStorage и диспетча
    const handleProductClick = (product, category) => {
        // Сохраняем данные в localStorage
        localStorage.setItem('product_id', product.id);
        localStorage.setItem('category', category.id);

        // Диспатчим действия
        dispatch(toggleDrawer('product', true)); // открытие drawer
        dispatch(setProductData(getCategoryName(categories)));
    };

    // Фильтрация продуктов на основе поискового запроса и фильтров
    const filteredProducts = categories[0]?.flatMap(category => {
        // Если выбрана категория "Все" или категория совпадает с выбранной, проверяем продукты
        if (selectedCategory === 'Все' || category.name === selectedCategory) {
            return category.products?.filter(product => {
                return (
                    product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                    parseFloat(product.price) <= priceRange
                );
            }) || []; // Обрабатываем случай, когда продуктов может не быть
        }
        return [];
    }) || [];

    return (
        <>
            <header className="text-white flex flex-col items-center justify-between py-4 relative">
                <div className="flex flex-row w-full items-center ml-4">
                    <div className="flex flex-row">
                        <div className="flex flex-col items-center justify-center">
                            <div className="ml-2 w-[35px] h-[35px] rounded-lg flex items-center justify-center">
                                <img src={avatar_icon} className="w-[35px] h-[35px] rounded-full" alt="avatar" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full ml-4">
                        <div className="flex flex-row">
                            <p className="text-gray-400 text-[14px]">
                                Salom {truncateString(localStorage.getItem('fullName'),12) }!
                            </p>
                            <img src={hand_icon} className="w-[20px] h-[15px]" alt="hand icon" />
                        </div>
                        <div className="flex flex-row items-center justify-start bg-green-700/50 text-[14px] rounded-md w-fit text-semi font-thin pr-1">
                            <img src={cash_icon} className="w-[25px] h-[25px] mr-2" />
                            {cashback} so'm
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <Typography className="text-[12px] text-gray-400">Текущий адрес</Typography>
                        <div className="w-full flex items-center text-gray-500">
                            <FaMapMarkerAlt className="mr-2 text-red-400" />
                            <p className="text-black text-[12px]">{address}</p>
                        </div>
                    </div>
                </div>
                <div className="p-4 w-full mt-4">
                    <div className="w-full bg-gray-700 p-2 rounded-lg pb-4">
                        <div className="m-2 relative flex flex-row items-center justify-center">
                            <img src={logo} className="w-[100px] h-[80px] absolute -top-[55px]" alt="logo" />
                            <Typography className="text-gray-200 text-[24px] mt-6">
                                ITALIANO PIZZA
                            </Typography>
                        </div>
                        <div className="flex flex-row items-center mt-1">
                            <div className="ml-2 flex flex-row items-center ml-10 w-full">
                                {/* Контейнер для поиска и фильтра */}
                                <div ref={searchContainerRef} className="relative flex items-center w-full">
                                    <div className="flex items-center transition-all duration-300 ease-in-out w-full">
                                        <div className="ml-4 flex flex-row items-center justify-center">
                                            <img src={chef_icon} className="w-[38px] h-[35px] mr-4" alt="chef icon" />
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Поиск по продуктам..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            className={`pl-6 pr-4 py-2 rounded-lg ${searchFocused ? 'backdrop-blur-none bg-white border border-none outline-none' : 'backdrop-blur-md bg-white h-[35px] border border-none rounded-lg p-0 text-[12]'} text-black transition-all duration-300 ease-in-out w-full`}
                                            onFocus={() => setSearchFocused(true)}
                                        />
                                        {/* Кнопка фильтров */}
                                        <button
                                            onClick={() => setFilterVisible(!filterVisible)}
                                            className="ml-2 flex items-center justify-center bg-gray-800 text-white rounded-lg px-2 py-1 transition-all duration-300 ease-in-out hover:bg-blue-800"
                                        >
                                            <BsFilterCircle className="text-white" />
                                            <span className="ml-1 hidden sm:block">Фильтры</span>
                                        </button>
                                    </div>
                                    {/* Список результатов поиска */}
                                    {searchFocused && searchQuery && (
                                        <div className="absolute top-12 left-0 w-full bg-white text-black rounded-lg shadow-lg py-2 max-h-60 overflow-y-auto z-10">
                                            {filteredProducts.length > 0 ? (
                                                filteredProducts.map((product, index) => (
                                                    ( product?.name &&
                                                        <div
                                                            key={index}
                                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                            onClick={() => handleProductClick(product, categories[0].find(cat => cat.products.includes(product)))}
                                                        >
                                                            {product.name} - {product.price} so'm
                                                        </div>
                                                    )
                                                ))
                                            ) : (
                                                <div className="px-4 py-2 text-gray-500">
                                                    Ничего не найдено
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Фон за фильтрами */}
            {filterVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={() => setFilterVisible(false)} />
            )}

            {/* Панель фильтров */}
            <div
                ref={filterContainerRef}
                className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-md bg-white shadow-lg rounded-lg z-20 p-6 transition-transform duration-300 ease-in-out ${filterVisible ? 'scale-100' : 'scale-0'}`}
            >
                <h2 className="text-xl font-semibold mb-4 text-center">Настройки фильтров</h2>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-700">Категория</label>
                    <select
                        className="w-full p-2 border border-gray-300 rounded"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option>Все</option>
                        {categories[0]?.map(category => (
                            <option key={category.id} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-700">Ценовой диапазон</label>
                    <input
                        type="range"
                        className="w-full"
                        min="0"
                        max="1000000"
                        value={priceRange}
                        onChange={(e) => setPriceRange(Number(e.target.value))}
                    />
                    <div className="text-center">{priceRange} so'm</div>
                </div>
                <button
                    onClick={() => setFilterVisible(false)}
                    className="bg-blue-500 text-white rounded-lg px-4 py-2 transition-all duration-300 ease-in-out hover:bg-blue-600 w-full"
                >
                    Применить фильтры
                </button>
            </div>
        </>
    );
};

export default Header;
