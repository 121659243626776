// src/redux/actions/categoryActions.js

// Определяем типы действий
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA';
export const ADD_CATEGORY = 'ADD_CATEGORY';

// Действие для установки данных категорий
export const setCategoryData = (categoryData) => ({
    type: SET_CATEGORY_DATA,
    payload: categoryData,
});

// Действие для добавления новой категории
export const addCategory = (category) => ({
    type: ADD_CATEGORY,
    payload: category,
});
