import React, { useEffect, useState } from 'react';
import { message, Typography } from 'antd';
import ContentCarusel from "./Carusel/ContentCarusel";
import half_pizza_icon from '../../../assets/icons/half_pizza_icon.png';
import CategoryCard from "./items/cards/CategoryCard";
import { BiSolidNews } from "react-icons/bi";
import NewsCard from "./items/NewsCard/NewsCard";
import bg_half from '../../../assets/img/pizza_half_bg.jpg';
import fetchCategories from "../../../api/Categories";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../../store/actions/drawerActions";
import { fetchNewsApi } from "../../../api/NewsApi";
import { addCategory } from "../../../store/actions/categoryActions";
import { setProductData } from "../../../store/actions/productActions";
import { getCategoryName } from "../../../utils";

const Content = () => {
    const lang = localStorage.getItem('lang') || 'uz';
    const [categories, setCategories] = useState([]);
    const [news, setNews] = useState([]);
    const dispatch = useDispatch();
    const drawerIsOpen = useSelector(state => state.drawer.isOpen);

    const fetchCate = async () => {
        const data = await fetchCategories(lang) || 'uz';
        setCategories(data);
        dispatch(addCategory(data));
    };

    const fetchNews = async () => {
        const data = await fetchNewsApi(lang);
        setNews(data);
    };

    useEffect(() => {
        fetchCate();
        fetchNews();
    }, []);

    const categoryHandler = (id) => {
        dispatch(toggleDrawer('category', true));
        localStorage.setItem('category', id);
        dispatch(setProductData(getCategoryName(categories)));
    };

    return (
        <main className="flex flex-col h-full w-full">
            <ContentCarusel />
            <div className={'w-full h-fit'}>
                <div className={'flex flex-row justify-between items-center w-full ml-2 mt-2'}>
                    <div className={'flex items-center mr-4  p-1'}>
                        <Typography className={'text-gray-600 font-bold text-xl'}>Категории</Typography>
                    </div>
                    <div className={'text-[12px] text-gray-500 font-thin mr-4'}>
                        показать всё
                    </div>
                </div>
                <div className={'w-full h-[230px] overflow-scroll'}>
                    <div className={'flex flex-wrap w-full items-center h-fit overflow-scroll mt-2'}>
                        {categories.map(value => (
                            <div key={value.id} onClick={() => categoryHandler(value.id)}>
                                <CategoryCard cover={value.icon.icon} name={value.name} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="relative w-full flex justify-center items-center h-[200px]">
                    <div
                        style={{
                            backgroundImage: `url('${bg_half}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            cursor: 'pointer'
                        }}
                        className="relative flex flex-col w-[90%] h-[150px] bg-gray-400 rounded-lg items-center justify-center  overflow-hidden"
                    >
                        <div className="absolute inset-0 bg-black opacity-50" />
                        <div onClick={ ()=> {
                            dispatch(toggleDrawer('half_pizza', true))
                        }} className="relative flex flex-col items-center justify-center">
                            <img src={half_pizza_icon} className="w-[100px] h-[100px] mt-2" alt="Half Pizza" style={{ opacity: '0.8' }} />
                            <Typography className="text-white bg-black/70 p-1 rounded-lg" level={3}>
                                Создай свою половинку
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={'flex flex-row w-full items-center mb-2'}>
                    <BiSolidNews className={'text-gray-700 ml-2'} />
                    <Typography className={'text-gray-700 font-bold ml-2'}>Новости</Typography>
                </div>
                <div className={'w-full h-[300px] overflow-scroll'}>
                    <div className={'flex flex-wrap w-full items-center justify-center h-[90%] overflow-scroll'}>
                        {news.map(newsItem => (
                            <NewsCard
                                key={newsItem.id}
                                cover={newsItem.cover}
                                title={newsItem.brief_description}
                                briefDescription={newsItem.brief_description}
                                fullDescription={newsItem.description}
                            />
                        ))}
                        <div className={'w-full h-[150px]'}></div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Content;
