import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL;

export const updateUserProfile = async (data) => {
    try {
        // Получаем токен из локального хранилища
        const token = localStorage.getItem('token');

        // URL для запроса обновления профиля пользователя
        const url = `${BASE_URL}api/user/profile/update`;

        // Преобразуем данные в формат application/x-www-form-urlencoded
        const params = new URLSearchParams();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params.append(key, data[key]);
            }
        }

        // Добавляем токен в заголовок запроса для авторизации
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${token}`
            }
        };

        // Отправляем PUT запрос на сервер с данными и авторизационным заголовком
        const response = await axios.put(url, params, config);

        // Проверяем успешность запроса
        if (response.status === 200) {
            console.log(response);
            return response.data;
        }

    } catch (error) {
        console.error('Ошибка при обновлении профиля пользователя:', error);
        throw error;
    }
};
