// store/actions/drawerActions.js

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export const toggleDrawer = (drawerType, isOpen, position = 'half-open') => ({
    type: TOGGLE_DRAWER,
    payload: {
        drawerType,
        isOpen,
        position, // 'closed', 'half-open', 'fully-open'
    },
});
