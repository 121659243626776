import React, { useState } from 'react';
import { FaBars, FaCog } from 'react-icons/fa';
import { IconSoup } from '@tabler/icons-react';
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from 'antd';
import { toggleDrawer } from "../../../store/actions/drawerActions";
import SettingsModal from "./settings/Settings";
import MenuModal from "./Menu/BottomMenu";
import Basket from "../Basket/Basket";


const BottomBar = () => {
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    const [isMenuModalOpen, setMenuModalOpen] = useState(false);
    const [isBasketDrawerOpen, setBasketDrawerOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOrder = () => {
        dispatch(toggleDrawer('basket', false,'closed'));
        setBasketDrawerOpen(true);
    };

    const handleSettingsOpen = () => {
        setSettingsModalOpen(true);
    };

    const handleSettingsClose = () => {
        setSettingsModalOpen(false);
    };

    const handleMenuOpen = () => {
        setMenuModalOpen(true);
    };

    const handleMenuClose = () => {
        setMenuModalOpen(false);
    };

    const handleDrawerClose = () => {
        setBasketDrawerOpen(false);
    };

    return (
        <>
            <div className="fixed  bottom-0 rounded-t-xl w-full bg-gray-700 border-t border-gray-300 shadow-lg flex justify-around items-center py-2 md:hidden">
                <button onClick={handleMenuOpen} className="flex bottom-menu-tour flex-col items-center text-gray-700 hover:text-blue-500 focus:outline-none">
                    <FaBars className="text-xl text-white" />
                    <span className="text-xs mt-1 text-white">Меню</span>
                </button>
                <button onClick={handleOrder} className="flex bottom-basket-tour flex-col items-center text-gray-700 hover:text-blue-500 focus:outline-none">
                    <IconSoup className="text-xl text-white" />
                    <span className="text-xs mt-1 text-white">Заказы</span>
                </button>
                <button onClick={handleSettingsOpen} className="flex bottom-setting-tour flex-col items-center text-gray-700 hover:text-blue-500 focus:outline-none">
                    <FaCog className="text-xl text-white" />
                    <span className="text-xs mt-1 text-white">Настройки</span>
                </button>
            </div>
            <Drawer
                title="Заказы"
                placement="bottom"
                height="100%"
                onClose={handleDrawerClose}
                visible={isBasketDrawerOpen}
                className="drawer-responsive"
                closeIcon={<span className="text-xl text-gray-700 rounded-xl">×</span>}
            >
                {/* Здесь можно вставить содержимое корзины */}
                <Basket/>
            </Drawer>
            <SettingsModal isOpen={isSettingsModalOpen} onClose={handleSettingsClose} />
            <MenuModal isOpen={isMenuModalOpen} onClose={handleMenuClose} onSettingsOpen={handleSettingsOpen} />

        </>
    );
};

export default BottomBar;
