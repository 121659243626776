// src/components/Tour/TourData.js

export const main = [
    {
        target: '.bottom-menu-tour',
        content: 'Здесь вы можете нажачать и увидить какие возможности доступно',
    },
    {
        target: '.bottom-basket-tour',
        content: 'Здесь хранится ваша корзина продукты которые добавлены сюда и история заказов. Здесь же можете оформить заказы',
    },
    {
        target: '.bottom-setting-tour',
        content: 'Здесь настройки вы можете менять информацию о себе адресс и так далле',
    },
];

export const orderTour = [
    {
        target: '.order-basket-tour',
        content: 'Здесь отображается продукты которые вы заказали если ничего нету то корзина пуста',
    },
    {
        target: '.order-history-tour',
        content: 'Здесь хранится ваша история заказов',
    },
];

