import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lottie from 'lottie-react';
import chef from '../../assets/json/animations/chef.json'
import time_device from '../../assets/json/animations/time_device.json'
import pizza from '../../assets/json/animations/pizza.json'
import cashback from '../../assets/json/animations/cashback.json'
import deleviry from '../../assets/json/animations/deleviry.json'
import logo from '../../assets/img/logo.png'
import {Typography} from "antd";

const WelcomeCarousel = ({onFinish}) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => {
            if (current === 3) { // последний слайд (0, 1, 2 для трех слайдов)
                setTimeout(() => {
                    localStorage.setItem('greetings', 'true');
                    onFinish();
                }, 500); // небольшая задержка перед переходом к основному контенту
            }
        }
    };

    return (
        <div className="w-screen h-screen bg-gradient-to-tr ">
            <Slider {...settings}>
                <div className="flex flex-col items-center justify-center overflow-scroll bg-gray-700 h-screen p-4">
                    <img src={logo} className={'w-[250px] h-[200px] m-auto self-center mt-4'}/>
                    <p className={'text-[40px] font-bold m-auto self-center text-center text-white'}> ITALIANO PIZZA</p>
                    <Lottie animationData={chef} loop={true}/>
                    <div className={'flex flex-col w-full text-white text-2xl font-thin bg-gray-600 p-2 rounded-lg'}>
                        Добро пожаловать в мир вкусной еды
                        Вы у нас можете попробовать не только пиццу на тонком тесто но и пышную с сырным бортом
                        {/*<div className={'w-full flex flex-row h-[60px] items-center  bg-blue-400/80 rounded-lg'}>*/}
                        {/*    <Lottie className={'w-[50px] h-[50px] mb-2'} animationData={time_device} loop={true}/>*/}
                        {/*    <Typography className={'text-white font-bold'}>Удобство и экономия времени</Typography>*/}
                        {/*</div>*/}
                        {/*<div className={'w-full flex flex-row h-[60px] items-center mt-2  bg-orange-400/80 rounded-lg'}>*/}
                        {/*    <Lottie className={'w-[50px] h-[50px] mb-2'} animationData={pizza} loop={true}/>*/}
                        {/*    <Typography className={'text-white font-bold'}>Вкусно и доступной цене</Typography>*/}
                        {/*</div>*/}

                    </div>
                </div>
                <div className="flex items-center justify-center bg-teal-700 h-screen">
                    <div className="flex flex-col items-center justify-center overflow-scroll  h-screen p-4">
                        <Lottie animationData={cashback} loop={true}/>
                        <div className={'flex flex-col w-full'}>
                            <Typography className={'text-[35px] font-thin text-white bg-teal-600 p-2 rounded-lg m-auto '}>
                                Заказывайте через наш бот и получите 10% бонуса от каждого заказа
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center bg-gray-700 h-screen">
                    <div className="flex flex-col items-center justify-center overflow-scroll  h-screen p-4">
                        <Lottie animationData={deleviry} loop={true}/>
                        <div className={'flex flex-col w-full'}>
                            <Typography className={'text-[35px] font-thin text-white bg-gray-600 p-2 rounded-lg mt-12'}>
                                Мы доставим ваш заказ быстро и до вашей двери
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center bg-gray-700 h-screen">
                    <div className="flex flex-col items-center justify-center overflow-scroll  h-screen p-4">
                        <div className={'flex flex-col w-full'}>
                            <Typography className={'text-[45px] font-thin text-white'}>
                                Подождите ...
                            </Typography>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default WelcomeCarousel;
