// src/App.js

import React, { useState, useEffect, useRef } from "react";
import Main from "./components/Main/Main";
import BottomDrawer from "./components/Drawer/Drawer";
import Auth from './components/Auth';
import { useSelector } from "react-redux";
import { selectIsUserLoggedIn } from "./store/selectors/userSelectors";
import BottomBar from "./components/Main/BottomBar/BottomBar";
import WelcomeCarousel from "./components/welcomeCarousel/WelcomeCarousel";
import { useTelegram } from './hooks/TelegramHook';
import { loginApi } from './api/AuthApi';
import { message } from "antd";
import Tour from "./Tour/Tour"; // Импортируем компонент Tour
import { main } from "./Tour/TourData";

function App() {
    const scroll_ref = useRef(null);
    const [showWelcome, setShowWelcome] = useState(localStorage.getItem('greetings') !== 'true');
    const [showTour, setShowTour] = useState(localStorage.getItem('tourCompleted') !== 'true');
    const { user, telegramId, tg } = useTelegram();
    const actionLogin = useSelector(selectIsUserLoggedIn);
    const token = localStorage.getItem('token');
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [debugInfo, setDebugInfo] = useState({});
    const [tg_id, setTgID] = useState();

    useEffect(() => {
        setTgID(telegramId);
    }, [telegramId]);

    useEffect(() => {
        if (localStorage.getItem('basket') === null) {
            localStorage.setItem('basket', '{"products":[]}');
        }

        tg.disableVerticalSwipes();
        tg.expand()

        if (localStorage.getItem('lang') === null) {
            localStorage.setItem('lang', 'uz');
        }
    }, [tg]);

    useEffect(() => {
        const savedPhoneNumber = localStorage.getItem('phoneNumber');
        const savedPassword = localStorage.getItem('password');

        if (savedPhoneNumber && savedPassword) {
            (async () => {
                try {
                    const response = await loginApi({ phoneNumber: savedPhoneNumber, password: savedPassword });
                    if (response && response.token) {
                        message.success("Автоматический вход выполнен успешно!");
                        localStorage.setItem('token', response.token);
                        setPhoneNumber(savedPhoneNumber);
                    } else {
                        message.error("Ошибка при автоматическом входе. Попробуйте еще раз.");
                    }
                } catch (error) {
                    console.error("Error during automatic login:", error);
                }
            })();
        }
    }, []);

    const handleFinishWelcome = () => {
        setShowWelcome(false);
    };

    const handleTourFinish = () => {
        setShowTour(false);
        localStorage.setItem('tourCompleted', 'true');
    };

    const isRegistered = () => {
        return token && token !== '';
    };

    return (
        <div className={'w-screen h-screen'}>
        <div ref={scroll_ref} className="w-screen h-screen overflow-scroll bg-gray-700">
            <div className="w-screen h-screen overflow-y-scroll overflow-x-hidden bg-[#efefef]">
                {showWelcome ? (
                    <WelcomeCarousel onFinish={handleFinishWelcome} />
                ) : (
                    !isRegistered() ? (
                        <Auth
                            user={user}
                            telegramId={tg_id}
                            phoneNumber={phoneNumber}
                        />
                    ) : (
                        <div>
                            <Main />
                            <BottomBar />
                            <BottomDrawer />
                            {showTour && <Tour data={main} onFinish={handleTourFinish} />} {/* Передаем шаги тура и функцию завершения */}
                        </div>
                    )
                )}
            </div>
        </div>
        </div>
    );
}

export default App;
