import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useTelegram } from "../hooks/TelegramHook";
import { loginApi, registerApi } from "../api/AuthApi";

const Auth = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { telegramId, user } = useTelegram();
    const [isLogin, setIsLogin] = useState(true);
    const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

    function removePlusSigns(text) {
        return text.replace(/^\+/, '');
    }


    useEffect(() => {

        const check = window.Telegram.WebApp.initDataUnsafe?.user?.id


        // Проверка на Telegram Web App
        if (check === undefined) {
            setIsTelegramWebApp(true);
        }
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            let response;
            if (isLogin) {
                response = await loginApi({
                    phoneNumber: removePlusSigns( values.phoneNumber),
                    password: values.password,
                });
                if (response && response.token) {
                    message.success("Вход выполнен успешно!");
                    localStorage.setItem('phoneNumber',removePlusSigns( values.phoneNumber));
                    localStorage.setItem('password', values.password);
                    localStorage.setItem('token', response.token);
                } else {
                    message.error("Ошибка при входе. Попробуйте еще раз.");
                }
            } else {
                response = await registerApi({
                    telegram_id: telegramId,
                    chat_id: telegramId,
                    fullname: values.fullname,
                    password: values.password,
                    phoneNumber: removePlusSigns(values.phoneNumber),
                });
                if (response && response.token) {
                    message.success("Регистрация прошла успешно!");
                    localStorage.setItem('phoneNumber',removePlusSigns( values.phoneNumber));
                    localStorage.setItem('password', values.password);
                    localStorage.setItem('token', response.token);
                } else {
                    message.error("Ошибка при регистрации. Попробуйте еще раз.");
                }
            }
            window.location.reload();
        } catch (error) {
            console.error("Error:", error);
            message.error("Произошла ошибка. Попробуйте еще раз.");
        } finally {
            setLoading(false);
        }
    };

    const handleTelegramRedirect = () => {
        setTimeout(() => {
            window.location.href = 'https://t.me/ItalianoPizza_offical_Bot';
        }, 100);
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm h-screen flex flex-col items-center justify-center">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    {!isLogin && (
                        <Form.Item
                            label="Full Name"
                            name="fullname"
                            rules={[{ required: true, message: 'Please enter your full name!' }]}
                        >
                            <Input placeholder="Full Name" />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[{ required: true, message: 'Please enter your phone number!' }]}
                    >
                        <Input placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    {isLogin ? (
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading} className="w-full">
                                Login
                            </Button>
                        </Form.Item>
                    ) : (
                        !isTelegramWebApp && (
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading} className="w-full">
                                    Register
                                </Button>
                            </Form.Item>
                        )
                    )}
                </Form>

                <div className="text-center mt-4">
                    {isLogin ? (
                        <p>
                            Нет аккаунта?{" "}
                            <span onClick={() => setIsLogin(false)} className="text-blue-500 cursor-pointer">Зарегистрируйтесь</span>
                        </p>
                    ) : (
                        <p>
                            Уже есть аккаунт?{" "}
                            <span onClick={() => setIsLogin(true)} className="text-blue-500 cursor-pointer">Войти</span>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Auth;
