import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Typography, Divider } from 'antd';
import { toggleDrawer } from "../../store/actions/drawerActions";
import SubCategories from "../SubCategories/SubCategories";
import Products from "../Products/Products";
import Basket from "../Main/Basket/Basket";
import HalfPage from "../Main/HalfPizza/HalfPage";
import bg from "../../assets/img/main_bg_white.png";
import bg_half from "../../assets/img/bg_half.png";

const BottomDrawer = React.memo(() => {
    const drawerIsOpen = useSelector(state => state.drawer.isOpen);
    const type = useSelector(state => state.drawer.type);
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(toggleDrawer(type, false, 'closed'));
    }, [dispatch, type]);

    const drawerContent = useMemo(() => {
        switch (type) {
            case 'basket':
                return <Basket />;
            case 'category':
                return <SubCategories />;
            case 'half_pizza':
                return <HalfPage />;
            case 'product':
                return <Products />;
            default:
                return null;
        }
    }, [type]);

    const drawerStyle = useMemo(() => {
        switch (type) {
            case 'half_pizza':
                return {
                    backgroundImage: `url(${bg_half})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            case 'product':
                return {
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            default:
                return {};
        }
    }, [type]);

    const drawerTitle = useMemo(() => {
        switch (type) {
            case 'basket':
                return "Корзина";
            case 'category':
                return "Категории";
            case 'half_pizza':
                return "Создай свою половинку";
            case 'product':
                return "Продукты";
            default:
                return "";
        }
    }, [type]);

    return (
        <>
            <Drawer
                title={<Typography.Title level={3} className="text-center">{drawerTitle}</Typography.Title>}
                placement="bottom"
                closable={true}
                onClose={handleClose}
                open={drawerIsOpen}
                height="100vh"
                className="custom-drawer pt-6"
                bodyStyle={drawerStyle}
            >

                {drawerContent}
            </Drawer>
        </>
    );
});

export default BottomDrawer;
