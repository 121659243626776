export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const ADD_PRODUCT = 'ADD_PRODUCT';

// Действие для установки данных продуктов
export const setProductData = (products) => ({
    type: SET_PRODUCT_DATA,
    payload: products,
});

// Действие для добавления нового продукта
export const addProduct = (product) => ({
    type: ADD_PRODUCT,
    payload: product,
});
