import React from 'react';

const CategoryCard = ({cover, name}) => {
    const apiUrl = process.env.REACT_APP_URL;
    const backgroundStyle = {
        backgroundImage: `url('${apiUrl}${cover}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        cursor: 'pointer', // Добавляем указатель мыши для области новости
    };
    return (
        <div className={'w-[80px] h-[80px] bg-white rounded-lg ml-4 mt-2 flex flex-col items-center justify-center'}>
            <div className={'w-[40px] h-[40px] rounded-full bg-gray-100'}>
                <div style={backgroundStyle} className={'w-[30px] h-[30px]  rounded-lg m-auto self-center mt-1'}>

                </div>
            </div>
            <p className={'text-gray-700 font-bold text-[14px] mt-2'}> {name}</p>

        </div>
    );
};

export default CategoryCard;
