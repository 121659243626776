// store/reducers/drawerReducer.js

import { TOGGLE_DRAWER } from '../actions/drawerActions';

const initialState = {
    isOpen: false,
    type: null,
    position: 'closed', // Добавляем начальное состояние позиции drawer
};

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                isOpen: action.payload.isOpen,
                type: action.payload.drawerType,
                position: action.payload.position,
            };
        default:
            return state;
    }
};

export default drawerReducer;
