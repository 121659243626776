import {configureStore} from "@reduxjs/toolkit";

import userReducer from "./reducers/userReducer";
import drawerReducer from "./reducers/drawerReducer";
import categoryReducer from "./reducers/categoryReducer";
import productReducer from "./reducers/productReducer";


export const store = configureStore({
    reducer : {

        user : userReducer,
        drawer : drawerReducer,
        category: categoryReducer,
        product: productReducer,
    }
})
