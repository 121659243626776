// src/redux/reducers/categoryReducer.js

import { SET_CATEGORY_DATA, ADD_CATEGORY } from '../actions/categoryActions';

// Начальное состояние
const initialState = {
    categories: [], // Массив для хранения категорий
};

// Reducer для управления состоянием данных категорий
const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY_DATA:
            return {
                ...state,
                categories: action.payload,
            };
        case ADD_CATEGORY:
            // Проверяем, существует ли категория с таким же id
            const existingCategory = state.categories.find(cat => cat.id === action.payload.id);
            if (existingCategory) {
                console.warn(`Category with ID ${action.payload.id} already exists`);
                return state; // Возвращаем текущее состояние без изменений
            }
            return {
                ...state,
                categories: [...state.categories, action.payload], // Добавляем новую категорию в массив
            };
        default:
            return state;
    }
};

export default categoryReducer;
