// src/redux/reducers/productReducer.js

import { SET_PRODUCT_DATA, ADD_PRODUCT } from '../actions/productActions';

// Начальное состояние
const initialState = {
    products: [], // Массив для хранения продуктов
};

// Reducer для управления состоянием данных продуктов
const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT_DATA:
            return {
                ...state,
                products: action.payload,
            };
        case ADD_PRODUCT:
            // Проверяем, существует ли продукт с таким же id
            const existingProduct = state.products.find(prod => prod.id === action.payload.id);
            if (existingProduct) {
                console.warn(`Product with ID ${action.payload.id} already exists`);
                return state; // Возвращаем текущее состояние без изменений
            }
            return {
                ...state,
                products: [...state.products, action.payload], // Добавляем новый продукт в массив
            };
        default:
            return state;
    }
};

export default productReducer;
