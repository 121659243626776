import React from 'react';
import Content from "./content/Content";
import background from '../../assets/img/background_main.png'
import Header from "./Header/Header";

const MainComponent = () => {

    return (
        <div   className="flex flex-col  h-screen">
            <Header/>
            <main className="flex-1  "><Content/></main>
            {/*<footer className="bg-gray-800 text-white py-4">Footer</footer>*/}
        </div>
    );
};

export default MainComponent;
