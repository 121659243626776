import React, { useState } from 'react';
import Joyride from 'react-joyride';

const Tour = ({ data, onFinish }) => {
    const [run, setRun] = useState(true);

    return (
        <Joyride
            steps={data}
            run={run}
            continuous
            showProgress
            showSkipButton
            callback={(data) => {
                const { status } = data;
                const finishedStatuses = ['finished', 'skipped'];
                if (finishedStatuses.includes(status) && onFinish) {
                    onFinish();
                }
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

export default Tour;
