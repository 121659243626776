import React, {useEffect} from 'react';
import { Typography } from "antd";
import HalfOrder from "./HalfOrder";
import './HalfPage.css';
import {useSelector} from "react-redux";
export default function HalfPage() {
    const productsData = useSelector(state => state.category);
    return (
        <div className="w-full flex flex-col h-screen ">
            <div className="flex flex-row w-full items-center justify-center mt-6">
                <HalfOrder categories={productsData} />
            </div>
            <div className=" flex flex-row w-full items-center justify-center mt-32 bg-black/40 rounded-xl">


                <Typography className="font-bold text-white ml-16  m-auto self-center text-[32px] bg-clip-text text-transparent gradient-text">
                    СОЗДАЙ СВОЮ ПОЛОВИНКУ !!!
                </Typography>
            </div>
        </div>
    );
}
