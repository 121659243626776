import axios from 'axios';
import {message} from "antd";

const BASE_URL = process.env.REACT_APP_URL

export const fetchNewsApi = async (lang) => {
    try {
        const token = localStorage.getItem('token');
        const url = `${BASE_URL}api/news/?language=${lang}`;

        // Добавляем токен в заголовок запроса для авторизации
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const response = await axios.get(url, config);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
    if (error.response.status ===401){
        message.info('Unauthorized');
        localStorage.removeItem('token');
        localStorage.removeItem('login');
    }

        console.error('Error while fetching categories:', error);
        throw error;
    }
};

