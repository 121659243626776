import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaNewspaper, FaThLarge, FaGamepad, FaCog, FaTimes } from 'react-icons/fa';
import phofilePhoto from '../../../../assets/icons/avatar_icon.png'
import {Typography, Tag, message} from 'antd';
import CategoryCard from "../../content/items/cards/CategoryCard";
import NewsCard from "../../content/items/NewsCard/NewsCard";
import fetchCategories from "../../../../api/Categories";
import { useDispatch } from "react-redux";
import { toggleDrawer } from "../../../../store/actions/drawerActions";
import { addCategory } from "../../../../store/actions/categoryActions";
import { setProductData } from "../../../../store/actions/productActions";
import { getCategoryName } from "../../../../utils";
import { fetchProducts } from "../../../../api/ProductsApi";
import { fetchNewsApi } from "../../../../api/NewsApi";
import NewsDetailsModal from "./NewsDetail";

const MenuModal = ({ isOpen, onClose, onSettingsOpen }) => {
    const [view, setView] = useState('menu');
    const [basket, setBasket] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [news, setNews] = useState([]);
    const [selectedNews, setSelectedNews] = useState(null);
    const dispatch = useDispatch();
    const lang = localStorage.getItem('lang');

    useEffect(() => {
        fetchProductsData();
    }, []);

    useEffect(() => {
        if (view === 'profile') {
            const storedBasket = JSON.parse(localStorage.getItem('basket'));
            setBasket(storedBasket?.products || []);
        }
        if (view === 'categories') {
            fetchCate();
        }
        if (view === 'news') {
            fetchNews();
        }
    }, [view]);

    const fetchProductsData = async () => {
        const data = await fetchProducts(localStorage.getItem('lang')||'uz');
        setProducts(data);
    };

    const fetchCate = async () => {
        const data = await fetchCategories(lang) || 'uz';
        setCategories(data);
        dispatch(addCategory(data));
    };

    const fetchNews = async () => {
        const data = await fetchNewsApi(lang) || 'uz';
        setNews(data);
    };

    const getProductDetails = (productId) => {
        if (!productId || !products.length) return null;
        return products.find(product => product.id.toString() === productId.toString());
    };

    const categoryHandler = (id) => {
        dispatch(toggleDrawer('category', true));
        localStorage.setItem('category', id);
        dispatch(setProductData(getCategoryName(categories)));
        onClose();
    };

    const getTotalAmount = () => {
        return basket.reduce((total, item) => {
            const productDetails = getProductDetails(item.product_id);
            if (productDetails) {
                let itemTotal = parseFloat(productDetails.price) * item.count;
                if (item.board && productDetails.chees_board_price) {
                    itemTotal += parseFloat(productDetails.chees_board_price) * item.count;
                }
                if (item.topics) {
                    Object.entries(item.topics).forEach(([topicName, isSelected]) => {
                        if (isSelected) {
                            const topic = productDetails.topicons.find(t => t.name === topicName);
                            if (topic) {
                                itemTotal += parseFloat(topic.price);
                            }
                        }
                    });
                }
                return total + itemTotal;
            }
            return total;
        }, 0);
    };

    const fullName = localStorage.getItem('fullName') || 'Имя пользователя';
    const cashback = localStorage.getItem('cashback') || '0';
    const phoneNumber = localStorage.getItem('phoneNumber') || 'Не указан';

    const menuItems = [
        { icon: <FaUser className="text-xl" />, label: 'Профиль', action: () => setView('profile') },
        { icon: <FaNewspaper className="text-xl" />, label: 'Новости', action: () => setView('news') },
        { icon: <FaThLarge className="text-xl" />, label: 'Категории', action: () => setView('categories') },
        { icon: <FaGamepad className="text-xl" />, label: 'Игры', action: () =>message.success('скора будет доступно !!!') },
        { icon: <FaCog className="text-xl" />, label: 'Настройки', action: onSettingsOpen },
    ];

    if (!isOpen) return null;

    return (
        <>
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3 p-4"
                >
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-semibold">
                            {view === 'menu' ? 'Меню' : view === 'profile' ? 'Профиль' : view === 'categories' ? 'Категории' : 'Новости'}
                        </h3>
                        <button onClick={() => view === 'menu' ? onClose() : setView('menu')} className="text-gray-700 hover:text-red-500">
                            <FaTimes className="text-xl" />
                        </button>
                    </div>
                    {view === 'menu' ? (
                        <div className="grid grid-cols-3 gap-4 mb-4">
                            {menuItems.map((item, index) => (
                                <motion.button
                                    key={index}
                                    onClick={item.action}
                                    className="flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow hover:bg-blue-100 focus:outline-none"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                >
                                    {item.icon}
                                    <span className="mt-2 text-sm">{item.label}</span>
                                </motion.button>
                            ))}
                        </div>
                    ) : view === 'profile' ? (
                        <>
                            <div className="flex items-center mb-4">
                                <img src={phofilePhoto} alt="Avatar" className="w-12 h-12 rounded-full mr-4" />
                                <div>
                                    <h4 className="text-lg font-semibold">{fullName}</h4>
                                    <p className="text-sm text-gray-600">Кэшбэк: {cashback}</p>
                                    <p className="text-sm text-gray-600">Телефон: {phoneNumber}</p>
                                </div>
                            </div>
                            <div className="border-t pt-4">
                                <h4 className="text-lg font-semibold mb-2">Корзина</h4>
                                <div className="overflow-auto max-h-[20vh] mt-2 mb-4">
                                    {basket.length > 0 ? (
                                        basket.map((item, index) => {
                                            const productDetails = getProductDetails(item.product_id);
                                            if (!productDetails) return null;
                                            return (
                                                <div key={index} className="flex items-center mb-2 p-2 bg-gray-100 rounded-lg shadow-sm">
                                                    <img
                                                        src={`${process.env.REACT_APP_URL}${productDetails.cover[0]}`}
                                                        alt={productDetails.name}
                                                        className="w-12 h-12 object-cover rounded-md"
                                                    />
                                                    <div className="ml-2 flex-1">
                                                        <Typography className="text-sm font-semibold">{productDetails.name}</Typography>
                                                        <Typography className="text-xs text-gray-500">Размер: {item.size}</Typography>
                                                        <Typography className="text-xs text-gray-500">Количество: {item.count}</Typography>
                                                        <div className="flex flex-wrap mt-2">
                                                            {item.topics && Object.entries(item.topics).map(([topicName, isSelected]) => (
                                                                isSelected && (
                                                                    <Tag color="blue" key={topicName}>
                                                                        {topicName}
                                                                    </Tag>
                                                                )
                                                            ))}
                                                        </div>
                                                        {item.board && (
                                                            <Tag color="green" className="mt-2">
                                                                Сырный борт
                                                            </Tag>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Typography className="text-center text-gray-500">Корзина пуста</Typography>
                                    )}
                                </div>
                                <Typography className="text-lg font-bold">Общая сумма: {getTotalAmount().toFixed(2)} so'm</Typography>
                            </div>
                        </>
                    ) : view === 'categories' ? (
                        <>
                            <div className="w-full h-fit">
                                <div className="flex flex-row justify-between items-center w-full ml-2 mt-2">
                                    <div className="flex items-center mr-4 p-1">
                                        <Typography className="text-gray-600 font-bold text-xl">Категории</Typography>
                                    </div>
                                    <div className="text-[12px] text-gray-500 font-thin mr-4">
                                        показать всё
                                    </div>
                                </div>
                                <div className="w-full h-[300px] overflow-scroll">
                                    <div className="flex flex-wrap w-full items-center h-fit overflow-scroll mt-2">
                                        {categories.map(value => (
                                            <div key={value.id} onClick={() => categoryHandler(value.id)}>
                                                <CategoryCard cover={value.icon.icon} name={value.name} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="w-full h-fit">
                                <div className="flex flex-row justify-between items-center w-full ml-2 mt-2">
                                    <div className="flex items-center mr-4 p-1">
                                        <Typography className="text-gray-600 font-bold text-xl">Новости</Typography>
                                    </div>
                                </div>
                                <div className="w-full h-[300px] overflow-scroll">
                                    <div className="flex flex-wrap w-full items-center h-fit overflow-scroll mt-2">
                                        {news.map((newsItem) => (
                                            <NewsCard
                                                key={newsItem.id}
                                                cover={newsItem.cover}
                                                title={newsItem.brief_description}
                                                fullDescription={newsItem.description}
                                                onClick={() => setSelectedNews(newsItem)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </motion.div>
            </div>
            <NewsDetailsModal
                isVisible={!!selectedNews}
                onClose={() => setSelectedNews(null)}
                newsItem={selectedNews}
            />
        </>
    );
};

export default MenuModal;
