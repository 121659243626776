import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Typography, Button, message, Input, Radio, TimePicker, Switch, Tag } from "antd";
import { fetchProducts } from "../../../api/ProductsApi";
import { makeOrder, getUserOrders } from "../../../api/orderApi";
import { ClockCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { BsHouseDoor, BsGeoAlt, BsClock, BsCash, BsCreditCard } from "react-icons/bs";
import moment from "moment";
import { FaTrash } from "react-icons/fa";
import { removeProductWithIdZero } from "../../../utils/index";
import {useSelector} from "react-redux";
import Tour from "../../../Tour/Tour";
import {orderTour} from "../../../Tour/TourData";

const Basket = () => {
    const [basket, setBasket] = useState([]);
    const [products, setProducts] = useState([]);
    const [showBasket, setShowBasket] = useState(true);
    const [showOrderForm, setShowOrderForm] = useState(false);
    const [useSavedAddress, setUseSavedAddress] = useState(true);
    const [customAddress, setCustomAddress] = useState("");
    const [desiredTime, setDesiredTime] = useState(null);
    const [orderHistory, setOrderHistory] = useState([]);
    const [instructions, setInstructions] = useState({
        knock: false,
        call: true
    });
    const [useCashBack, setUseCashBack] = useState(false);
    const [cashBackAmount, setCashBackAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("cash");
    const [showTour , setShowTour] = useState(false);
    const fetchBasket = useCallback(() => {
        const storedBasket = JSON.parse(localStorage.getItem('basket')) || { products: [] };
        removeProductWithIdZero(storedBasket.products);
        localStorage.setItem('basket', JSON.stringify(storedBasket));
        setBasket(storedBasket.products);

    }, []);
    const drawerPosition = useSelector(state => state.drawer.position);
    useEffect(() => {
        fetchBasket();
    }, [drawerPosition]);

    useEffect(() => {
        const fetchProductsData = async () => {
            const data = await fetchProducts(localStorage.getItem('lang') || 'uz');
            setProducts(data);
        };

        fetchProductsData().catch(error => {
            console.error("Ошибка при загрузке продуктов:", error);
            message.error("Не удалось загрузить продукты.");
        });
    }, []);

    useEffect(() => {
        if (!showBasket) {
            const fetchOrderHistory = async () => {
                try {
                    const response = await getUserOrders();
                    setOrderHistory(response.orders || []);
                } catch (error) {
                    console.error("Ошибка при загрузке истории заказов:", error);
                    message.error("Не удалось загрузить историю заказов.");
                }
            };

            fetchOrderHistory();
        }
    }, [showBasket]);

    const getProductDetails = useCallback((productId) => {
        if (!productId || !products.length) return null;
        return products.find(product => product.id.toString() === productId.toString());
    }, [products]);

    const getTotalAmount = useCallback(() => {
        return basket.reduce((total, item) => {
            let itemTotal = 0;

            if (item.isHalf) {
                const firstProduct = getProductDetails(item.pizza_1);
                const secondProduct = getProductDetails(item.pizza_2);

                if (firstProduct && secondProduct) {
                    const size = item.size.toLowerCase();
                    const firstPrice = firstProduct[`size_${size}_price`] || firstProduct.price;
                    const secondPrice = secondProduct[`size_${size}_price`] || secondProduct.price;

                    const halfPrice = (parseFloat(firstPrice) + parseFloat(secondPrice)) / 2;
                    itemTotal = halfPrice * item.count;
                }
            } else {
                const productDetails = getProductDetails(item.product_id);
                if (productDetails) {
                    let itemPrice = 0;
                    if (item.size === 'Small' && productDetails.size_small_price) {
                        itemPrice = parseFloat(productDetails.size_small_price);
                    } else if (item.size === 'Medium' && productDetails.size_medium_price) {
                        itemPrice = parseFloat(productDetails.size_medium_price);
                    } else if (item.size === 'Large' && productDetails.size_large_price) {
                        itemPrice = parseFloat(productDetails.size_large_price);
                    } else {
                        itemPrice = parseFloat(productDetails.price);
                    }

                    if (item.board) {
                        if (item.size === 'Small' && productDetails.chees_board_size_small_price) {
                            itemPrice += parseFloat(productDetails.chees_board_size_small_price);
                        } else if (item.size === 'Medium' && productDetails.chees_board_size_medium_price) {
                            itemPrice += parseFloat(productDetails.chees_board_size_medium_price);
                        } else if (item.size === 'Large' && productDetails.chees_board_size_large_price) {
                            itemPrice += parseFloat(productDetails.chees_board_size_large_price);
                        }
                    }

                    itemTotal = itemPrice * item.count;

                    if (item.topics) {
                        Object.entries(item.topics).forEach(([topicName, isSelected]) => {
                            if (isSelected) {
                                const topic = productDetails.topicons.find(t => t.name === topicName);
                                if (topic) {
                                    itemTotal += parseFloat(topic.price) * item.count;
                                }
                            }
                        });
                    }
                }
            }

            return total + itemTotal;
        }, 0);
    }, [basket, getProductDetails]);

    const handleOrder = async () => {
        if (basket.length === 0) {
            message.warning("Ваша корзина пуста.");
            return;
        }

        const savedAddress = localStorage.getItem('address');
        const address = useSavedAddress ? savedAddress : customAddress;

        if (!address) {
            message.error("Пожалуйста, укажите адрес для доставки.");
            return;
        }

        const userCashBack = parseFloat(localStorage.getItem('cashback')) || 0;

        if (useCashBack && cashBackAmount > userCashBack) {
            message.error("Сумма кешбека превышает доступный лимит.");
            return;
        }

        const timeDescription = desiredTime
            ? `Желаемое время доставки: ${desiredTime.format('HH:mm')}\n`
            : "";
        const instructionsDescription = `
            ${!instructions.knock ? "Не стучать в дверь\n" : "Стучать в дверь\n"}
            ${!instructions.call ? "Не звонить\n" : "Позвонить\n"}
        `;

        const description = `Описание заказа\n${timeDescription}${instructionsDescription}\nСпособ оплаты: ${paymentMethod === 'cash' ? 'Наличные' : 'Карта'}`;

        try {
            const orderData = {
                address: address,
                location: null,
                description: description,
                products: basket,
                useCashBack: useCashBack ? cashBackAmount : 0
            };

            const response = await makeOrder(orderData);

            if (response?.order?.status === 'pending') {
                const order = response.order;
                message.success(`Ваш заказ #${order.id} успешно оформлен на сумму ${order.total} so'm!`);

                localStorage.removeItem('basket');
                setBasket([]);
                setShowBasket(false);
                setShowOrderForm(false);
            } else {
                message.error("Ошибка при оформлении заказа. Пожалуйста, попробуйте снова.");
            }
        } catch (error) {
            console.error("Ошибка при оформлении заказа:", error);
            message.error("Не удалось оформить заказ.");
        }
    };

    const handleToggleChange = (key) => {
        setInstructions(prevInstructions => ({
            ...prevInstructions,
            [key]: !prevInstructions[key]
        }));
    };

    const handleRemoveItem = useCallback((index) => {
        const updatedBasket = basket.filter((_, i) => i !== index);
        setBasket(updatedBasket);
        localStorage.setItem('basket', JSON.stringify({ products: updatedBasket }));
        message.success("Товар удален из корзины.");
    }, [basket]);

    const handleClearBasket = useCallback(() => {
        setBasket([]);
        localStorage.removeItem('basket');
        localStorage.setItem('basket', JSON.stringify({ products: [] }));
        message.success("Корзина очищена.");
    }, []);

    const BasketItem = useMemo(() => {
        return ({ item, index, onRemove }) => {
            const api_url = process.env.REACT_APP_URL;
            const { product_id, count, size, topics, board, pizza_1, pizza_2, isHalf } = item;

            let productDetails, firstProductDetails, secondProductDetails;
            if (isHalf) {
                firstProductDetails = getProductDetails(pizza_1);
                secondProductDetails = getProductDetails(pizza_2);
            } else {
                productDetails = getProductDetails(product_id);
            }

            if (!productDetails && !firstProductDetails && !secondProductDetails) {
                console.warn(`Product details not found for product_id: ${product_id}`);
                return null;
            }

            return (
                <div className="flex flex-col bg-white rounded-lg mb-4">
                    <div className="flex flex-row items-center p-4 bg-white shadow-md rounded-lg">
                        {isHalf ? (
                            <div className="flex flex-row w-full items-center">
                                <div className="relative flex items-center justify-center w-32 h-32">
                                    <div className="w-32/2 h-64/2 overflow-hidden">
                                        <div className="w-64/2 h-64/2 rounded-full overflow-hidden">
                                            <img src={`${api_url}${firstProductDetails.cover[0]}`} alt="Left Half Circle" className="w-64/2 h-64/2 object-cover" />
                                        </div>
                                    </div>
                                    <div className="w-32/2 h-64/2 overflow-hidden transform rotate-180">
                                        <div className="w-64/2 h-64/2 rounded-full overflow-hidden">
                                            <img src={`${api_url}${secondProductDetails.cover[0]}`} alt="Right Half Circle" className="w-64/2 h-64/2 object-cover" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col ml-4 w-full">
                                    <Typography className="text-xl font-bold">50/50 Пицца</Typography>
                                    <Typography className="text-gray-500 mt-1">Левая половинка: {firstProductDetails.name}</Typography>
                                    <Typography className="text-gray-500 mt-1">Правая половинка: {secondProductDetails.name}</Typography>
                                    <Typography className="text-gray-500 mt-1">Размер: {size}</Typography>
                                    <Typography className="text-gray-500 mt-1">Количество: {count}</Typography>
                                    {board && (
                                        <Tag color="green" className="mt-2">Сырный борт</Tag>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <img src={`${api_url}${productDetails.cover[0]}`} alt={productDetails.name} className="w-24 h-24 object-cover rounded-md" />
                                <div className="flex flex-col ml-4 w-full">
                                    <Typography className="text-xl font-bold">{productDetails.name}</Typography>
                                    <Typography className="text-gray-500 mt-1">Размер: {size}</Typography>
                                    <Typography className="text-gray-500 mt-1">Количество: {count}</Typography>
                                    <div className="flex flex-wrap mt-2">
                                        {topics && Object.entries(topics).map(([topicName, isSelected]) => (
                                            isSelected && (
                                                <Tag color="blue" key={topicName}>{topicName}</Tag>
                                            )
                                        ))}
                                    </div>
                                    {board && (
                                        <Tag color="green" className="mt-2">Сырный борт</Tag>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <Button
                        onClick={() => onRemove(index)}
                        className="w-full flex justify-center bg-red-500 text-white items-center mt-2"
                        type="text"
                        icon={<FaTrash className="mr-2" />}
                    >
                        Удалить
                    </Button>
                </div>
            );
        };
    }, [getProductDetails]);

    return (
        <div className="flex flex-col mt-4 p-4 bg-gray-100 shadow-lg rounded-lg relative overflow-scroll">
            {showOrderForm ? (
                <div className="mt-2">
                    <Typography className="text-xl font-bold mb-4">Адрес доставки</Typography>
                    <Typography className="text-md font-semibold">Дополнительные инструкции:</Typography>
                    <div className="flex flex-col space-y-2 mt-2">
                        <div className="flex items-center justify-between p-2 border rounded-lg shadow-sm">
                            <div className="flex items-center space-x-2">
                                <BsHouseDoor className="text-xl text-blue-500" />
                                <Typography className="text-sm">Стучать в дверь</Typography>
                            </div>
                            <Switch checked={instructions.knock} onChange={() => handleToggleChange('knock')} />
                        </div>
                        <div className="flex items-center justify-between p-2 border rounded-lg shadow-sm">
                            <div className="flex items-center space-x-2">
                                <PhoneOutlined className="text-xl text-green-500" />
                                <Typography className="text-sm">Позвонить</Typography>
                            </div>
                            <Switch checked={instructions.call} onChange={() => handleToggleChange('call')} />
                        </div>
                    </div>

                    <Typography className="text-md font-semibold mt-4">Выберите адрес:</Typography>
                    <Radio.Group
                        onChange={(e) => setUseSavedAddress(e.target.value)}
                        value={useSavedAddress}
                        className="w-full mt-2"
                    >
                        <Radio value={true} className="w-full flex items-center justify-between p-2 border rounded-lg shadow-sm mb-2">
                            <div className="flex items-center space-x-2">
                                <BsGeoAlt className="text-xl text-red-500" />
                                <span className="text-sm">{localStorage.getItem('address')}</span>
                            </div>
                        </Radio>
                        <Radio value={false} className="w-full flex items-center justify-between p-2 border rounded-lg shadow-sm">
                            <div className="flex items-center space-x-2">
                                <BsGeoAlt className="text-xl text-gray-500" />
                                <span className="text-sm">Ввести новый адрес</span>
                            </div>
                        </Radio>
                    </Radio.Group>

                    {!useSavedAddress && (
                        <Input
                            placeholder="Введите новый адрес"
                            value={customAddress}
                            onChange={(e) => setCustomAddress(e.target.value)}
                            className="mt-2 p-2 border rounded-lg shadow-sm"
                        />
                    )}

                    <Typography className="text-md font-semibold mt-4">
                        <BsClock className="text-xl text-purple-500" /> Желаемое время доставки:
                    </Typography>
                    <TimePicker
                        value={desiredTime}
                        onChange={(time) => setDesiredTime(time)}
                        format="HH:mm"
                        placeholder="Выберите время"
                        className="w-full mt-2 p-2 border rounded-lg shadow-sm"
                        minuteStep={15}
                        showNow={false}
                        popupClassName="rounded-lg shadow-lg"
                        disabledHours={() => {
                            const hours = [];
                            for (let i = 0; i < moment().hour(); i++) {
                                hours.push(i);
                            }
                            return hours;
                        }}
                        disabledMinutes={(selectedHour) => {
                            const minutes = [];
                            if (selectedHour === moment().hour()) {
                                for (let i = 0; i < moment().minute(); i++) {
                                    minutes.push(i);
                                }
                            }
                            return minutes;
                        }}
                    />

                    <Typography className="text-md font-semibold mt-4">Использовать кешбек:</Typography>
                    <div className="flex items-center space-x-2 mt-2">
                        <Switch checked={useCashBack} onChange={() => setUseCashBack(!useCashBack)} />
                        {useCashBack && (
                            <Input
                                type="number"
                                placeholder="Сумма кешбека"
                                value={cashBackAmount}
                                onChange={(e) => setCashBackAmount(parseFloat(e.target.value) || 0)}
                                min={0}
                                max={parseFloat(localStorage.getItem('cashback')) || 0}
                                className="mt-2 p-2 border rounded-lg shadow-sm"
                            />
                        )}
                    </div>

                    {parseFloat(localStorage.getItem('cashback')) === 0 && (
                        <Typography className="text-md font-semibold mt-2 text-red-500">
                            У вас нет доступного кешбека. Сделайте заказ, чтобы заработать кешбек!
                        </Typography>
                    )}

                    <Typography className="text-md font-semibold mt-4">Способ оплаты:</Typography>
                    <Radio.Group
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        value={paymentMethod}
                        className="w-full mt-2"
                    >
                        <Radio value="cash" className="w-full flex items-center justify-between p-2 border rounded-lg shadow-sm mb-2">
                            <div className="flex items-center space-x-2">
                                <BsCash className="text-xl text-green-500" />
                                <span className="text-sm">Наличные</span>
                            </div>
                        </Radio>
                        <Radio value="card" className="w-full flex items-center justify-between p-2 border rounded-lg shadow-sm">
                            <div className="flex items-center space-x-2">
                                <BsCreditCard className="text-xl text-blue-500" />
                                <span className="text-sm">Карта</span>
                            </div>
                        </Radio>
                    </Radio.Group>

                    <div className="mt-4">
                        <Typography className="text-md font-semibold">Продукты в корзине:</Typography>
                        {basket.length > 0 ? (
                            <div className="overflow-auto max-h-[20vh] mt-2 mb-4">
                                {basket.map((item, index) => (
                                    <BasketItem key={index} item={item} index={index} onRemove={handleRemoveItem} />
                                ))}
                            </div>
                        ) : (
                            <Typography className="text-center text-gray-500">Корзина пуста</Typography>
                        )}
                        <Typography className="text-lg font-bold">Общая сумма: {getTotalAmount().toFixed(2)} so'm</Typography>
                    </div>

                    <Button type="primary" size="large" className="w-full mt-4" onClick={handleOrder}>
                        Подтвердить заказ
                    </Button>
                    <Button type="default" size="large" className="w-full mt-2" onClick={() => setShowOrderForm(false)}>
                        Отменить
                    </Button>
                </div>
            ) : (
                <>
                    <div onClick={()=>{
                setShowTour(true);
                    }} className={'w-full text-center mb-6 bg-green-500 rounded text-white font-semibold '}>Как это работает ?</div>
                    <div className="flex flex-row items-center justify-center mb-4">
                        <div className="flex space-x-4">
                            <Button
                                className={'order-basket-tour'}
                                type={showBasket ? "primary" : "default"}
                                onClick={() => setShowBasket(true)}>
                                Корзина
                            </Button>
                            <Button
                                className={'order-history-tour'}
                                type={!showBasket ? "primary" : "default"}
                                onClick={() => setShowBasket(false)}>
                                История заказов
                            </Button>
                        </div>
                    </div>

                    {showBasket ? (
                        basket.length > 0 ? (
                            <div className="overflow-auto max-h-[70vh] mb-4">
                                <div className="w-full grid grid-cols-1 gap-4">
                                    {basket.map((item, index) => (
                                        <BasketItem key={index} item={item} index={index} onRemove={handleRemoveItem} />
                                    ))}
                                </div>
                                <Button type="danger" className="w-full mt-4" onClick={handleClearBasket}>
                                    Очистить корзину
                                </Button>
                            </div>
                        ) : (
                            <Typography className="text-center text-gray-500">Корзина пуста</Typography>
                        )
                    ) : (
                        <OrderHistory orders={orderHistory} />
                    )}

                    {showBasket && basket.length > 0 && (
                        <div className="sticky bottom-0 left-0 right-0 p-4 bg-white shadow-lg flex flex-col items-center rounded-b-lg">
                            <Typography className="text-xl font-bold">
                                Общая сумма: {getTotalAmount().toFixed(2)} so'm
                            </Typography>
                            <Button type="primary" size="large" className="my-2" onClick={() => setShowOrderForm(true)}>
                                Оформить заказ
                            </Button>
                        </div>
                    )}
                </>
            )}
            {showTour &&     <Tour data={orderTour}/>}

        </div>
    );
};

const OrderHistory = ({ orders }) => {
    const getOrderStatusColor = (status) => {
        switch (status) {
            case 'on hold':
                return 'bg-orange-100 text-orange-800 border-orange-200';
            case 'pending':
                return 'bg-blue-100 text-blue-800 border-blue-200';
            case 'completed':
                return 'bg-green-100 text-green-800 border-green-200';
            default:
                return 'bg-gray-100 text-gray-800 border-gray-200';
        }
    };

    return (
        <div className="flex flex-col mt-4 p-4 bg-gray-100 shadow-lg rounded-lg max-h-[70vh] overflow-y-auto">
            <Typography className="text-2xl font-bold mb-4">История заказов</Typography>
            {orders.length > 0 ? (
                orders.slice().reverse().map((order) => (
                    <div
                        key={order.id}
                        className={`p-4 mb-4 shadow-md rounded-lg border-l-4 ${getOrderStatusColor(order.status)}`}
                    >
                        <div className="flex justify-between items-center">
                            <Typography className="text-xl font-bold">Заказ #{order.id}</Typography>
                            <Tag color={getOrderStatusColor(order.status).split(' ')[3]}>{order.status}</Tag>
                        </div>
                        <Typography className="text-gray-500">Дата: {new Date(order.date).toLocaleDateString()}</Typography>
                        <Typography className="text-gray-500">Сумма: {order.total} so'm</Typography>
                        <Typography className="text-gray-500">адресс: {order.address}</Typography>
                        <Typography className="text-gray-500 mt-2">Продукты:</Typography>
                        <ul className="list-disc ml-5">
                            {order.order_products && order.order_products.map((orderProduct) => (
                                <li key={orderProduct.id}>
                                    {orderProduct.product.name} - {orderProduct.count} шт.
                                </li>
                            ))}
                        </ul>
                    </div>
                ))
            ) : (
                <Typography className="text-center text-gray-500">История заказов пуста</Typography>
            )}

        </div>
    );
};

export default Basket;
